<template>
    <div class="rental-order">
        <div class=" pl-5 pb-8 pt-2 pt-lg-6 ">

            <div class="container-fluid ">
                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0" >Objednávka č. {{order.order_id}}</h3>
                    <p style="font-size:12px; font-weight:bold;margin-top:5px;">{{order.created}}</p>

                </div>
            </div>
        </div>
        <div class="container-fluid rental-order">
            <div class="card-container mt--8">


                            <template>
                                <div class="info-bars">
                                    <a-row :gutter="16">
                                        <a-col :span="18">

                                     <a-card title="Produkty v objednávke" class="mb-3">
                                         <template>

                                             <table class="w-100">
                                                 <template v-for="product in products">
                                                 <template >
                                                    <tr :key="product.nid">
                                                     <td width="60"><span v-if="product.data.image" class="text-center"><img :src="product.data.image.styled" style="max-height:30px;max-width:30px;"></span>  <a-checkbox></a-checkbox></td>
                                                     <td width="65%"><router-link :to="'/edit-content/' + product.id"> <h5 style="margin-bottom:0"><span v-if="product.qty > 1" style="font-weight:bold;color:red;font-size:20px;">{{product.qty}} x </span>{{product.title}}</h5></router-link> <template v-if="product.data.author_datas"><span v-for="author in product.data.author_datas">{{author}}</span> - <span v-for="author in product.data.distribution_datas">{{author}}</span> - <span v-for="vydavatelid in product.data.vydavatelid_datas">{{vydavatelid}}</span> - </template>{{product.data.ean}}  <template v-if="product.data.kusov"> - {{ product.data.kusov }}</template> <template v-if="product.data.sklad"> - {{ product.data.sklad }}</template></td>
                                                     <td width="100" style="font-weight:bold"><span v-if="product.price === 0">Zadarmo</span><span v-else>{{Number(product.price).toFixed(2) + ' €'}}</span></td>
                                                     <td class="text-right"> <a-select v-model="product.status" class="w-100">
                                                         <a-select-option :value="0">Čakáme na dodanie</a-select-option>
                                                         <a-select-option :value="1">Pripravené na odoslanie</a-select-option>
                                                         <a-select-option :value="2">Vyskladnené</a-select-option>
                                                         <a-select-option :value="3">Nedostupné</a-select-option>
                                                         </a-select></td>
                                                 </tr></template>
                                                 </template>
                                             </table>
                                        </template>
                                    </a-card>
                                               <a-card title="Fakturačné údaje" class="mb-3">
                                        <span slot="extra"></span>
                                        <template>
                                            <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                                                <div class="pl-lg-4">
                                                     <a-divider>Fakturačná adresa</a-divider>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Meno">    <a-input  alternative=""
                                                                        label="Meno"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.billing_first_name"
                                                            />
                                                        </a-form-item></div>
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Priezvisko">    <a-input  alternative=""
                                                                        label="Priezvisko"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.billing_last_name"
                                                            />
                                                        </a-form-item></div>

                                                    </div>
                                                     <div class="row">
                                                    <div class="col-lg-6">
                                                     <a-form-item label="Telefón">    <a-input  alternative=""
                                                                    label="Telefón"
                                                                    input-classes="form-control-compact"
                                                                    v-model="order.billing_phone"
                                                        />   </a-form-item>                                                    </div>
                                                    <div class="col-lg-6">
                                                     <a-form-item label="Firma">    <a-input  alternative=""
                                                                    label="Firma"
                                                                    input-classes="form-control-compact"
                                                                    v-model="order.billing_company"
                                                        />   </a-form-item>                                                    </div>
                                                </div>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Ulica">    <a-input  alternative=""
                                                                        label="Ulica"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.billing_street1"
                                                            />
                                                        </a-form-item></div>
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Ulica 2">    <a-input  alternative=""
                                                                        label="Ulica 2"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.billing_street2"
                                                            />
                                                        </a-form-item></div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Mesto">    <a-input  alternative=""
                                                                        label="Mesto"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.billing_city"
                                                            />
                                                        </a-form-item></div>
 <div class="col-lg-6">
                                                         <a-form-item label="PSČ">    <a-input  alternative=""
                                                                        label="PSČ"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.billing_postal_code"
                                                            />
                                                        </a-form-item></div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-6">
                                                         <a-form-item label="Krajina">    <a-input  alternative=""
                                                                        label="Krajina"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.billing_country"
                                                            />
                                                        </a-form-item></div>
  <div class="col-lg-6">
                                                         <a-form-item label="IČO">    <a-input  alternative=""
                                                                        label="IČO"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.ico"
                                                            />
                                                        </a-form-item></div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-6">
                                                         <a-form-item label="DIČ">    <a-input  alternative=""
                                                                        label="DIČ"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.dic"
                                                            />
                                                        </a-form-item></div>
                                                         <div class="col-lg-6">
                                                         <a-form-item label="IČDPH">    <a-input  alternative=""
                                                                        label="IČDPH"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.icdph"
                                                            />
                                                        </a-form-item>


                                                    </div>
                                                    </div> </div>


  <div class="pl-lg-4"  v-if="order.shiping">
           <a-divider>Adresa doručenia</a-divider>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <a-form-item label="">
                                                            <a-checkbox v-model="order.shiping.same">Rovnaká ako fakturačná</a-checkbox>
                                                        </a-form-item>
                                                    </div>
                                                </div>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Meno">    <a-input  alternative=""
                                                                        label="Meno"
                                                                        :disabled="order.shiping.same"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.delivery_first_name"
                                                            />
                                                        </a-form-item></div>
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Priezvisko">    <a-input  alternative=""
                                                                        label="Priezvisko"
                                                                         :disabled="order.shiping.same"
                                                                       input-classes="form-control-compact"
                                                                        v-model="order.delivery_last_name"
                                                            />
                                                        </a-form-item></div>

                                                    </div> <div class="row">
                                                    <div class="col-lg-6">
                                                     <a-form-item label="Telefón">    <a-input  alternative=""
                                                                    label="Telefón"
                                                                     :disabled="order.shiping.same"
                                                                       input-classes="form-control-compact"
                                                                    v-model="order.delivery_phone"
                                                        />   </a-form-item>                                                    </div>
                                                    <div class="col-lg-6">
                                                     <a-form-item label="Firma">    <a-input  alternative=""
                                                                    label="Firma"
                                                                     :disabled="order.shiping.same"
                                                                       input-classes="form-control-compact"
                                                                    v-model="order.delivery_company"
                                                        />   </a-form-item>                                                    </div>
                                                </div>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Ulica">    <a-input  alternative=""
                                                                        label="Ulica"
                                                                        :disabled="order.shiping.same"
                                                                        input-classes="form-control-compact"
                                                                        v-model="order.delivery_street1"
                                                            />
                                                        </a-form-item></div>
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Ulica 2">    <a-input  alternative=""
                                                                        label="Ulica 2"
                                                                          :disabled="order.shiping.same"
                                                                      input-classes="form-control-compact"
                                                                        v-model="order.delivery_street2"
                                                            />
                                                        </a-form-item></div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Mesto">    <a-input  alternative=""
                                                                        label="Mesto"
                                                                         :disabled="order.shiping.same"
                                                                       input-classes="form-control-compact"
                                                                        v-model="order.delivery_city"
                                                            />
                                                        </a-form-item></div>
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Oblasť">    <a-input  alternative=""
                                                                        label="Oblasť"
                                                                         :disabled="order.shiping.same"
                                                                       input-classes="form-control-compact"
                                                                        v-model="order.delivery_zone"
                                                            />
                                                        </a-form-item></div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                         <a-form-item label="PSČ">    <a-input  alternative=""
                                                                        label="PSČ"
                                                                          :disabled="order.shiping.same"
                                                                      input-classes="form-control-compact"
                                                                        v-model="order.delivery_postal_code"
                                                            />
                                                        </a-form-item></div>
                                                        <div class="col-lg-6">
                                                         <a-form-item label="Krajina">    <a-input  alternative=""
                                                                        label="Krajina"
                                                                         :disabled="order.shiping.same"
                                                                       input-classes="form-control-compact"
                                                                        v-model="order.delivery_country"
                                                            />
                                                        </a-form-item></div>

                                                    </div>
                                                       </div>
                                                <div class="pl-lg-4">
                                                    <a-divider>Nová poznámka</a-divider>
                                                    <div class="form-group">
                                                     <a-form-item  :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                                                            <a-textarea :rows="4"  class="form-control form-control-alternative" v-model="order.newnote"></a-textarea>
                                                         </a-form-item>                                                    </div>
                                                </div>

                                            </a-form>
                                        </template>
                                    </a-card>

                                   <a-card shadow type="secondary" title="Dokumenty"  class="no-left-top-corner" v-if="order.invoices">
                                     <div slot="extra"> <a-button @click="openCampaignModal" type="primary" ><i class="fad fa-layer-plus"></i> Pridať dokument</a-button>
                                    </div>

                                    <a-modal title="Nový dokument" centered  v-model="campaignModal"  @cancel="campaignModal = false" v-if="campaignModal" @ok="addDocument">
                                        <template>
                                            <a-select  class=" w-100" v-model="newDocument" :options="data"/>

                                        </template>
                                    </a-modal>

                                    <a-table
                                            :columns="columns_documentation"
                                            :rowKey="record => record.id"
                                            :dataSource="order.invoices"
                                            :loading="loading"
                                            class="table-flush">


                                        <template slot="date" slot-scope="detailed">
                                            {{formatDateP(detailed)}}
                                        </template>
                                        <template slot="pdf" slot-scope="pdf">

                                            <a target="_blank" :href="url + '/../../' + pdf"> <a-button   type="primary" ><i class="fas fa-file-pdf pr-1"></i> otvor dokument</a-button></a></template>
                                        <template slot="name" slot-scope="user">
                                            <router-link :to="'/edit-document/' + user.id" class="btn text-default btn-icon btn-secondary btn-sm" :href="'/edit-document/' + user.id"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Upraviť</router-link>
                                            <a-popconfirm placement="topRight"
                                                          @confirm="confirm(user.id)"
                                                          @cancel="cancel"
                                                          okText="Áno"
                                                          cancelText="Nie">
                                                <template slot="title">
                                                    <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                                    Položka bude trvalo odstránená z vašej stránky.
                                                </template>
                                                <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                            </a-popconfirm>
                                        </template>
                                    </a-table>

                                </a-card>


   <a-card title="Poznámky" class="mt-3">
                                                <span slot="extra"></span>

                                                <a-timeline>
                                                    <a-timeline-item :key="'note-order-' + oind" v-for="(item,oind) in order.notes" :color="item.color">
                                                        <i slot="dot" :class="item.icon" style="font-size: 12px;" v-if="item.icon" />
                                                        <div class="note-content">
                                                            <small>{{formatDate(item.created)}}</small>
                                                            <div><strong>{{item.title}}</strong></div>  <span v-html="item.message"></span></div>
                                                    </a-timeline-item>
                                                </a-timeline>
                                            </a-card>




                                        </a-col>
                                        <a-col :span="6">



                                            <a-card class="mb-3 important">

                                            <div class="mb-3">
                                               <div class="address-item"> Status objednávky</div>
                                                    <a-select  :defaultValue="order.order_status"  v-model="order.order_status" class="w-100 mt-1" :options="statuses"/>
                                                <a-checkbox class="m-3" v-model="order.sendemail">Odoslať oznámenie emailom</a-checkbox>
                                                </div>
                                                <div class="address-item" v-if="order.resnumb"><label>Variabilný symbol:</label>{{order.vs}}</div>
                                                <div class="address-item"><label>Suma v objednávke:</label><b>{{Number(order.finalprice).toFixed(2) + ' €'}}</b></div>
                                                <br><div class="address-item"><label>Vytvorené:</label> {{order.created}}</div>
                                                <div class="address-item"><label>Metóda platby:</label>
                                                    <span v-if="order.payment_method === 'cod' || order.payment === ''" >platba na recepcii</span>
                                                    <span v-if="order.payment_method === 'payout'">kartou</span>
                                                    <span v-if="order.payment_method === 'cardpay'">kartou</span>
                                                    <span v-if="order.payment_method === 'webpay'">karta</span>
                                                    <span v-if="order.payment_method === 'bank'">bankový prevod</span>
                                                    <span color="orange"  v-if="order.payment === 'bank'">VS: {{record.vs}}</span></div>
                                                <br>
                                                <div class="address-item"><label>Číslo zákazníka:</label> {{order.uid}}</div>
                                                <div class="address-item"><label>Email:</label> <a :href="'mailto:' + order.primary_email">{{order.primary_email}}</a></div>
                                                <div class="address-item"><label>Telefón:</label> <a :href="'tel:' + order.delivery_phone">{{order.delivery_phone}}</a></div>

                                                <div class="text-right">
                                                    <button @click="handleSubmit" class="btn btn-primary">Uložiť</button>
                                                </div>
                                           </a-card>

                                           <a-card title="Sledovanie zásielky" class="mb-3"  v-if="order.shiping">
                                                <span slot="extra">
                                                </span>
                                                <a-form-item label="Trackovací kód"   :label-col="{ span: 24 }" :wrapper-col="{ offset:1, span: 24 }">
                                <a-input class="form-control-compact w-100" :min="0" :step="0.1" v-model="order.shiping.tracking" placeholder="Sem zadajte kód od doručovacej spoločnosti" />
                                    <a v-if="order.shiping.tracking" :href="order.shiping.tracking" target="_blank">Sledovanie</a>
                                    </a-form-item>

                                                <a-button @click="sendTracking" class="mt-1">Uložiť kód</a-button>
                                            </a-card>
                                           <a-card title="Poštovné" class="mb-3"  v-if="order.shiping">
                                                <span slot="extra">
                                                         <a-tag  color="red" v-if="order.shiping.shipment == 'packeta-5' || order.shiping.shipment == 'packeta-10' || order.shiping.shipment == 'packeta-15'">Packeta</a-tag>
                                 <a-tag  color="orange" v-if="order.shiping.shipment == 'balikadresa-5' || order.shiping.shipment == 'balikadresa-10' || order.shiping.shipment == 'balikadresa-15'">Balík na adresu</a-tag>
                                 <a-tag  color="orange" v-if="order.shiping.shipment == 'balikposta-5' || order.shiping.shipment == 'balikposta-10' || order.shiping.shipment == 'balikposta-15'">Slovenská pošta</a-tag>
                                                </span>
                                                <div class="address-item"><label>Varianta:</label> {{order.shiping.shipment}}</div>
                                                <div class="address-item" v-if="order.shiping.variantID != '0' && order.shiping.variantID"><label>VariantID:</label> {{order.shiping.variantID}}</div>
                                                <div class="address-item" v-if="order.shiping.variant != '0' && order.shiping.variant"><label>PacketaBox:</label><div>{{order.shiping.variant}}</div></div>
                                                <div class="address-item" v-if="order.shiping.price != '0'"><label>Cena:</label> {{order.shiping.price}} €</div>
                                                <a-button @click="shippingModal = true" v-if="false" class="mt-2">Pripraviť zásielku</a-button>
                                            </a-card>
                                            <a-card title="Adresa doručenia" class="mb-3">
                                              <span slot="extra"></span>
                                              <template v-if="order.shiping">
                                              <span v-if="order.shiping.same">
                                                <div class="address-item">{{order.billing_first_name}} {{order.billing_last_name}}</div>
                                                <div class="address-item">{{order.billing_street1}}</div>
                                                <div class="address-item">{{order.billing_city}} {{order.billing_postal_code}}</div><br>
                                                <div class="address-item"><label>Telefón:</label><a :href="'tel:' + order.billing_phone">{{order.billing_phone}}</a></div>
                                                </span>
                                                <span v-else>
                                                <div class="address-item">{{order.delivery_first_name}} {{order.delivery_last_name}}</div>
                                                <div class="address-item">{{order.delivery_street1}}</div>
                                                <div class="address-item">{{order.delivery_city}} {{order.delivery_postal_code}}</div><br>
                                                <div class="address-item"><label>Telefón:</label><a :href="'tel:' + order.delivery_phone">{{order.delivery_phone}}</a></div>
                                                </span>
                                            </template>
                                            </a-card>

                                            <a-card title="Fakturačná adresa" class="mb-3" v-if="order.data">
                                                <span slot="extra"></span>
                                                <div class="address-item"><strong><span v-if="order.data.billing_company">{{order.data.billing_company}}</span><span v-else>{{order.delivery_first_name}} {{order.delivery_last_name}}</span></strong></div>
                                                <div class="address-item">{{order.billing_first_name}} {{order.billing_last_name}}</div>
                                                <div class="address-item">{{order.billing_street1}}</div>
                                                <div class="address-item">{{order.billing_city}} {{order.billing_postal_code}}</div><br>
                                              <div class="address-item"><label>IČO:</label> <span v-if="order.ico">{{order.ico}}</span><span v-else>nie je</span></div>
                                                <div class="address-item"><label>DIČ:</label> <span v-if="order.dic">{{order.dic}}</span><span v-else>nie je</span></div>
                                                <div class="address-item"><label>IČDPH:</label> <span v-if="order.icdph">{{order.icdph}}</span><span v-else>nie je</span></div>
                                            </a-card>

                                        </a-col>


                                    </a-row>
                                </div>
                            </template>



                        <a-modal title="Príprava zásielky" centered  v-model="shippingModal" v-if="shippingModal" @ok="shippingSend">
                            <a-form-item label="Váha zásielky v gramoch"   :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
                                <a-input-number class="form-control-compact" :min="0" :step="0.1" v-model="order.shiping.weight" />
                                     </a-form-item>
                            </a-modal>




                        <a-modal title="Služba k zvýhodnenému pobytu" centered  v-model="newModalReservation"  @cancel="cancelModal" v-if="newModalReservation">
                            <template  v-if="newExtra">
                                <div  class="new-reservation rental-order">
                                    <div class="form-group w-100"><label>Termín: </label>
                                        <a-range-picker class="form-control-compact" v-if="newExtra.datefrom" :default-value="[moment.unix(newExtra.datefrom),moment.unix(newExtra.dateto)]" style="border:0;margin-top:-5px;" v-model="newExtra.daterange"  :locale="locale"  @change="getRental" />
                                        <a-range-picker class="form-control-compact" v-else  style="border:0;margin-top:-5px;" v-model="newExtra.daterange"  :locale="locale"  @change="getRental" />

                                    </div>
                                    <div class="form-group w-100"  v-if="Object.keys(bikes).length > 0"><label>Bike:</label>
                                        <a-select class="form-control-compact"  style="border:0;" v-model="newExtra.product">

                                            <a-select-opt-group  v-for="(model,key) in bikes">
                                            <span slot="label" class="font-weight-bolder" style="font-weight:bold; color:#000;font-size:13px;padding-top:20px;">
                                             <i class="fad fa-biking pr-2" />{{bikeModels[key]}}</span>
                                                <a-select-option :value="bike.value" v-for="bike in model">
                                                    {{bike.label}}
                                                </a-select-option>
                                            </a-select-opt-group>
                                        </a-select>
                                    </div>
                                    <div class="form-group w-100"><label>Servisný deň</label>
                                        <span class="form-control-compact alternative-height" style="border:0;"><a-switch v-model="newExtra.serviceday" default-checked style="margin-top:-30px;"/></span></div>
                                    <div class="form-group w-100"><label>Rozdielna cena</label>
                                     <a-form-item label="">    <a-input-number class="form-control-compact" :min="0" :max="10" :step="0.1" v-model="newExtra.price" />
                                     </a-form-item>                                    </div>
                                    <div class="form-group w-100"><label>Doplnky:</label>
                                        <a-select
                                                mode="multiple"  class="form-control-compact"
                                                v-model="newExtra.additions"
                                                placeholder="Vyberte si doplnkovú službu">
                                            <a-select-option v-for="(op, indd) in additions" :key="op.value">{{op.label}}</a-select-option
                                            >
                                        </a-select>
                                    </div></div>

                                <template slot="footer"  style="padding-top:0;">
                                    <a-button  type="white" class="ml-auto" @click="newModalReservation = false; newExtra = {};">Zatvoriť
                                    </a-button>
                                    <a-button type="primary" @click="addReservation"> Vytvoriť</a-button>

                                </template>
                            </template>
                        </a-modal>



            </div>
        </div>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import {Modal} from 'ant-design-vue'
    import FormItem from 'ant-design-vue'
    import {Cropper} from 'vue-advanced-cropper'
    import moment from 'moment'

    import locale from 'ant-design-vue/es/date-picker/locale/sk_SK';
    Vue.use(FormItem, Modal);
    Vue.use(Cropper);

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }


    export default {
        name: 'order',
        props: ['id'],

        data() {
            return {
                loading: false,
                visible: false,
                originInfo: '',
                imageUrl: '',
                newDocument:false,
                fileList: [],
                data: false,
                url:API_URL,
                locale,
                moment: moment,
                bikes: {},
                shippingModal: false,
                roleOptions: [],
                imgCroppFile: '',
                statuses:[
                    {"value":"pending","label":"Čaká sa"},
                    {"value":"payment_recieved","label":"Platba prijatá"},
                    {"value":"instock","label":"Naskladnené položky"},
                    {"value":"shiped","label":"Tovar vyexpedovaný"},
                    {"value":"storno","label":"Storno"},
                    {"value":"abandoned","label":"Opustená"},
                    {"value":"completed","label":"Kompletná"},
                ],
                uploading: false,
                croppedImage: '',
                bikeModels:[],
                additions:[],
                imageUploaded: '',
                newExtra: {'serviceday':true,product: ''},
                columns : [
                    {
                        title: '',
                        scopedSlots: {customRender: 'image'},
                        width: '100px'
                    },
                    {
                        title: 'Produkt',
                        scopedSlots: {customRender: 'title'}
                    },
                    {
                        title: 'Cena',
                        dataIndex: 'price',
                        scopedSlots: {customRender: 'price'}
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        scopedSlots: {customRender: 'status'}
                    },
                ],
                columns_documentation : [
                    {
                        title: 'Názov',
                        dataIndex: 'title'
                    },
                    {
                        title: 'Vytvorené',
                        dataIndex: 'created',
                        scopedSlots: {customRender: 'date'}
                    },
                    {
                        title: 'PDF',
                        dataIndex: 'invoice',
                        scopedSlots: {customRender: 'pdf'}
                    },
                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                        width: '440px'
                    }
                ],
                roles: '',
                campaignModal:false,
                products: [],
                head: authHeader(),
                colorsto: JSON.parse(localStorage.colors),
                send: API_URL,
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
                orderId: {
                    uid: this.id
                },
                order: {
                    first_name: '',
                    last_name: '',
                    image: this.imageUploaded,
                },
                newModalReservation: false,
                model: {
                    ordername: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    address: '',
                    city: '',
                    country: '',
                    zipCode: '',
                    about: '',
                }
            }
        },
        methods: {
          addToOberon()
            {

                dataService.axiosFetch("invoicedata/" + this.id);
            },
            downloadDocument(pdf)
            {
 dataService.axiosFetch('getinvoicefile/' + pdf).then(results => {

     this.forcePDFDownload(results);
     })},
     forcePDFDownload(response){
 var arrBuffer = this.base64ToArrayBuffer(response.file);
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([arrBuffer], {type:"application/pdf"}))


                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', response.filename) //or any other extension
                document.body.appendChild(link)
                link.click()
            },
            addDocument()
            {

                dataService.axiosPost("newdocument", {order_id: this.id,type: this.newDocument}).then(results => {
                    this.newDocument = false;
                    this.campaignModal = false;
                    this.fetch();
                });
            },
            sendTracking()
            {

console.log(this.order.shiping);
dataService.axiosPost("tracking", {order_id: this.id, tracking: this.order.shiping.tracking}).then(results => {
                 this.fetch();
                });
            },
            shippingSend()
            {
console.log(this.order.shiping);
dataService.axiosPost("slovposta", {order_id: this.id, weight: this.order.shiping.weight}).then(results => {
                    console.log(results);
                });
            },
            downloadWithVueResource(url) {
                console.log(url);
                this.$http({
                    method: 'get',
                    url: url,
                    responseType: 'arraybuffer'
                })
                    .then(response => {
                        this.forceFileDownload(response)
                    })
                    .catch(() => console.log('error occured'))

            },
            forceFileDownload(response){
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'file.png') //or any other extension
                document.body.appendChild(link)
                link.click()
            },
            downloadItem ({ url, label }) {
                Axios.get(url, { responseType: 'blob' })
                    .then(response => {
                        const blob = new Blob([response.data], { type: 'application/pdf' })
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = label
                        link.click()
                        URL.revokeObjectURL(link.href)
                    }).catch(console.error)
            },
            showModal() {
                this.visible = true;
            },
            openCampaignModal() {
                this.campaignModal = true;
            },
            formatDate: function (dt) {
                return moment.unix(dt).format('D.M.YYYY H:mm')

            },
            formatDateP: function (dt) {
                return moment(dt).format('D.M.YYYY H:mm')

            },
            formatDateN: function (dt) {
                return moment.unix(dt).format('D.M.YYYY')

            },
            changeProduct(product)
            {
                console.log(product);
                this.newExtra = product.data;

                this.newExtra.id = product.nid;
                this.newModalReservation = true;
            },
            change({coordinates, canvas}) {
                this.croppedImage = canvas.toDataURL();
                this.imgCroppFile = canvas.toDataURL("image/jpeg");

            },
            confirm(e) {
                dataService.axiosDelete("document", e).then(results => {
                    if (results) {
                        this.order.rentalDetails.documentation.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            reservationDelete(r) {
                dataService.axiosDelete('rental', r).then(results => {
                    if (results) {
                        this.newModalReservation = false;
                        this.fetch();
                    }
                });
            },
            cancelModal()
            {
                console.log("fired");
                this.newModalReservation = false;
                this.bikes = {};
                this.newExtra = {};
            },
            addReservation()
            {
                if(this.newExtra.product)
                {
                    this.newExtra.order_id = this.id;
                    dataService.axiosPost("arent", this.newExtra).then(results => {
                        this.newExtra = {};
                        this.newModalReservation = false;
                        this.fetch();
                    });
                }
                else {
                    return false
                }
            },
            cancel()
            {
                this.newModalReservation = false;
            },
            backEndDateFormat(date) {
                return moment(date._d).format('YYYY-MM-DD');
            },
            getRental: function (rese) {

                var from = this.backEndDateFormat(rese[0]);
                var to = this.backEndDateFormat(rese[1]);

                //  let daterange = [moment(rese.datefrom, this.rdateFormat), moment(rese.dateto, this.rdateFormat)];


                dataService.axiosFetch("getrental/" + from + "/" + to).then(results => {
                    this.bikes = results;
                });
            },
            handleChange(info) {

            },
            openReservationModal()
            {
                this.chooseTimes = {};
                this.newModalReservation = true;
            },
            sayshit(e) {
                console.log(e);
            },
            handleSubmit() {
                let $order_data = this.order;

                dataService.axiosPost(this.$options.name, $order_data).then(results => {
                    if (results.data.success) {
                        this.$message.success('Obsah bol upravený');
                        this.fetch();
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
            fetch()
            {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.order = results;
                    var pa = this;
                    pa.products = [];
                    results.products.forEach(obj => {
                        if(obj.data.title)
                            pa.products.push(obj);
                    });

                });

            },
            normFile(e) {
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;


            },
        },
        mounted() {
            if (!isNaN(this.id)) {

                this.fetch();
                dataService.axiosFetch("content/addition").then(results => {
                    this.additions = dataService.getCheckboxOptions(results);

                });
                dataService.axiosFetch('documents').then(results => {
                    var roleops = [];
                    results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });

                    this.data = roleops;
                });

                var pa = this;
                dataService.axiosFetch("bikes").then(results => {

                    results.rooms.forEach(obj => {
                        pa.bikeModels[parseInt(obj.id)] = obj.title;
                    });


                });


            }
        }
    };
</script>
<style>
    .card-container .important label
    {margin-bottom:5px;}
    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .note-content
    {display:inline-block;
        margin-left:10px;
        width:100%;}
    .note-content small
    {float:right;}
    .ant-upload.ant-upload-select-picture-card
    {
        background-color: transparent;
        border:0;}
    .avatar-uploader
    {z-index: 9999;
        position: relative;
        display: block;
        border-radius: 100%;
        overflow: hidden;
        top: -12px;}
    .ant-upload.ant-upload-select-picture-card > .ant-upload
    {color:#fff;}
    .card-container {
        padding: 24px;
    }
    .description p{
        font-size: 0.875rem;
    }
    .card-container > .ant-tabs-card > .ant-tabs-content {
        margin-top: -16px;
    }
    .no-left-top-corner
    {border-top-left-radius: 0}

    .card-container > .ant-tabs-card > .ant-tabs-bar {
        border: 0;
        margin-bottom:12px;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
        font-weight:bold;
        background: #f2f4f9;
    }
    .card-container .info-bars .ant-card-body
    {min-height:150px;}
    .info-bars .ant-card-extra i
    {opacity:.3;font-size:20px;}
    .address-item label{
        font-weight:bold;
        margin:0;
        margin-right:5px;

    }

    .rental-order .form-group.has-label
    {margin:0;}
    .rental-order .form-group.has-label .form-control-label
    {
        margin:0;
        padding-top:12px;
        line-height:30px;
        padding-bottom:12px;
    }
    .rental-order .form-control-compact {
        float: right;
        width: 60%;
        margin-right: 5%;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        border-top-width: 1.02px;
        overflow: visible;
    }
    .alternative-height
    {
        line-height:45px;

    }
    .info-bars .ant-list-item img
    {max-width:100px;}
    .info-bars .ant-list-item
    {padding-right:20px;
        border-bottom:0!important;}
    .ct-rental .image-place
    {width:100px}
    .ct-rental h4
    {font-size:14px;width:100%;display:inline-block;}
    .ct-rental img
    {max-height:80px;}
    .ct-rental:not(.index-0)
    {border-top: 1px solid #ebebeb;margin-top:20px;padding-top:30px;}

    .ct-rentaddition
    {border-top: 1px solid #f5f5f5;}
    .ct-rental h4,.model-delivery h4
    {color:#333}

    .model-delivery
    {min-height:100px;margin-top:20px;
        margin-bottom:13px;
        border-top: 1px solid #ebebeb; }
    .ct-rentaddition img
    {width:80px;margin-right:20px;}
    .no-image-placeholder
    {width:100px; text-align:center;
        color:#000;
    }
    .no-image-placeholder i
    {font-size:30px; padding:0;}
    .canceled-true:not(.model-delivery)
    {border-top: 1px solid #ebebeb;
    }
    .canceled-true:not(.model-delivery) .no-image-placeholder i{
        padding-top:3px;
        padding-bottom:3px;
        opacity:.1;

    }
    .card-container .ant-card-extra,.card-container .ant-card-head-title
    {padding:10px 0;}
    .card-container .ant-card-head {
        min-height: unset;
    }
    .white-footer .ant-table-footer{
        background-color:#fff;
        border-top:2px solid #e8e8e8
    }
    .font-size-30
    {font-size:30px;}
    .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
        border-color: #fff;
        background: #f7fafc;
        z-index: 9;
    }
    .white-footer .ant-table-title
    {display:none;}
    .canceled-true:not(.model-delivery)
    {
        text-decoration: line-through;
        opacity:.6}
</style>
